<template>
    <div class="verifyEmailDiv">
        <div class="verifyEmailDivTitle">
            VERIFYING EMAIL
        </div>
    </div>
</template>
<script>
import { api } from '../helpers/Helpers';
export default {
    
    data() {
        return {
        }
    },
    methods: {
        verifyEmail: async function(token, email){
            const res = await api.verifyEmail(token, email)
            if (res!= null && res._id != undefined){

                const resStripe = await api.callCustomerApi(res.email);
                
                let _name = ''
                _name = res.firstName.charAt(0) + res.lastName.charAt(0);
                _name = _name.toUpperCase();
                var userInfo = {
                    loginType: 'api',
                    api: {
                        auth: 'alyosha',
                        user: {
                            name: _name,
                            firstname: res.firstName,
                            lastname: res.lastName,
                            agencyname: res.agencyName,
                            email: res.email,
                            profileImage: "testurl"
                        },
                        permissionLevel: res.permissionLevel,
                        customerId: resStripe
                    },
                    id: res.userId
                }
                this.$store.commit('setLoginUser', userInfo)


                window.open("/","_self");// "_blank"
            }
        }
    },
    
    mounted(){
        this.verifyEmail(this.$route.query.token, this.$route.query.email);
    }
}
</script>